import React, { Component } from 'react'
import { observer } from 'mobx-react'

import type { DiffuseTvState } from '@diffuse.tv/player-core'

export interface DebugComponentProps {
  state: DiffuseTvState
}

@observer
export class DebugComponent extends Component<DebugComponentProps> {
  render() {
    const debugInfo = this.props.state.screen.debugInfo

    return (
      <div
        style={{
          position: 'absolute',
          display: 'block',
          top: 20,
          bottom: 'auto',
          left: 20,
          right: 'auto',
          width: '450px',
          height: 'auto',
          padding: '0 15px',
          background: `rgba(40,40,40,0.5)`,
          color: 'white',
          textAlign: 'left',
          fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
          fontSize: '0.65em',
          overflowWrap: 'break-word',
          zIndex: 99999,
        }}
      >
        <h4>Stats for nerds:</h4>

        {(debugInfo?.sections || []).map((section, i) => (
          <section key={i}>
            <h3 style={{ marginBottom: '4px' }}>{section.title}</h3>
            {section.items.map(({ label, value }, j) => (
              <div key={j} style={{ marginBottom: '2px' }}>
                <b>{label}:</b>{' '}
                {(Array.isArray(value) && (
                  <div style={{ marginBottom: '1px' }}>
                    {value.map((val, key) => (
                      <span key={key} style={{ marginLeft: '6px' }}>
                        - {val}
                        <br />
                      </span>
                    ))}
                  </div>
                )) ||
                  (value === null && 'null') ||
                  (typeof value === 'object' && (
                    <div style={{ marginBottom: '1px' }}>
                      {Object.entries(value).map(([key, val]) => (
                        <span key={key} style={{ marginLeft: '6px' }}>
                          <b>{key}</b>: {val} <br />
                        </span>
                      ))}
                    </div>
                  )) ||
                  (typeof value === 'string' && value) ||
                  (typeof value === 'boolean' && value ? 'true' : 'false') ||
                  value}
                <br />
              </div>
            ))}
          </section>
        ))}
      </div>
    )
  }
}
