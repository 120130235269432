import React, { PureComponent } from 'react'
import type { DiffuseTvState } from '@diffuse.tv/player-core'

import styled from '@emotion/styled'

import Logo from '../components/logo'
import Display from '../components/icons/display'
import Archive from '../components/icons/archive'

export default class ArchivedComponent extends PureComponent<{
  state: DiffuseTvState
}> {
  render() {
    return (
      <PaddedWrapper>
        <Container>
          <Logo
            width="57.5rem"
            height="11.25rem"
            style={{ margin: '0 2.5rem' }}
          />
          <NameContainer>
            <Display
              width="3rem"
              height="3rem"
              stroke="white"
              style={{ flexShrink: 0 }}
            />
            <Text>{this.props.state.player.data?.name}</Text>
          </NameContainer>
          <MessageContainer>
            <Archive width="3rem" height="2.75rem" />
            <Text>This player is archived</Text>
          </MessageContainer>
        </Container>
      </PaddedWrapper>
    )
  }
}

const PaddedWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.5rem;
  background-color: white;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6d0fb1;
  position: relative;
`

const NameContainer = styled.div`
  left: 2.5rem;
  bottom: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
`

const MessageContainer = styled.div`
  top: 2.5rem;
  right: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 0.25rem;
  background-color: rgba(255, 255, 255, 0.21);
  position: absolute;
`

const Text = styled.span`
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 1.43;
  margin-left: 1rem;
  white-space: nowrap;
`
