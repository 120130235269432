import { caller } from 'postmsg-rpc'
import {
  ICacheFS,
  logger,
  Multihash,
  StorageMedium,
  StorageMediumId,
  URL,
} from '@diffuse.tv/player-core'
import { rpcOptions } from '../service-worker-loader'

const debug = logger(`CacheFS Web Client`, 'debug')

export const cachefs: ICacheFS = {
  async setup(): Promise<boolean> {
    debug('setup: called')

    // TODO
    return true
  },

  getStorageMediums: caller<StorageMedium[], []>(
    'cachefs_getStorageMediums',
    rpcOptions
  ),
  start: caller<boolean, [StorageMediumId]>('cachefs_start', rpcOptions),
  stop: caller<boolean, [StorageMediumId]>('cachefs_stop', rpcOptions),
  list: caller<Multihash[], [StorageMediumId]>('cachefs_list', rpcOptions),

  has: caller<boolean, [StorageMediumId, Multihash]>('cachefs_has', rpcOptions),
  download: caller<boolean, [StorageMediumId, Multihash, URL]>(
    'cachefs_download',
    rpcOptions
  ),
  invalidate: caller<boolean, [StorageMediumId, Multihash]>(
    'cachefs_invalidate',
    rpcOptions
  ),
  garbageCollector: caller<Multihash[], [StorageMediumId]>(
    'cachefs_garbageCollector',
    rpcOptions
  ),

  getUrl: (storageMediumId: StorageMediumId, multihash: Multihash): URL =>
    `/cachefs/${multihash}`,
}
