import React, { FC, useEffect } from 'react'
import type { ImageComponentProps } from './index'

export const Image: FC<ImageComponentProps> = ({
  hidden,
  broadcastInfo,
  onEnded,
  // onError,
}) => {
  // apply settimeout
  useEffect(() => {
    if (!onEnded || hidden || !broadcastInfo) return
    if (hidden) return

    const timeout = setTimeout(() => onEnded(), broadcastInfo.duration)

    return () => clearTimeout(timeout)
  }, [broadcastInfo, hidden, onEnded])

  return (
    (!!broadcastInfo?.url && (
      <div
        key={broadcastInfo?.unique_id || ''}
        style={{
          width: '100%',
          height: '100%',
          backgroundImage: `url(${broadcastInfo?.url || ''})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
    )) ||
    null
  )
}
