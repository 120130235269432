import React, { FC } from 'react'
import styled from '@emotion/styled'
import { DiffuseTvState } from '@diffuse.tv/player-core'

const ScreenLayoutComponent: FC<{
  state: DiffuseTvState
}> = ({ children }) => {
  return (
    <ScreenLayoutWrapper className="screen-layout-wrapper">
      {children}
    </ScreenLayoutWrapper>
  )
}

export default ScreenLayoutComponent

const ScreenLayoutWrapper = styled.main`
  width: 100%;
  height: 100%;
  position: relative;
  color: white;
  background: black;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-family: 'Fira Sans';
`
