import type { IAsyncStorage, IBindings } from '@diffuse.tv/player-core'

import { database } from './database'
import { filesystem } from './filesystem'
import { hardware } from './hardware'
import { reportError } from '../sentry'

export const bindings: IBindings = {
  database,
  filesystem,
  hardware,
  storage: localStorage as unknown as IAsyncStorage,
  debug: {
    reportError,
  },
}
