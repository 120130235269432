import { NODE_ENV, ENGAGEMENT_URL, GRAPHQL_URL, DASHBOARD_URL } from './config'
import { DiffuseTvState } from '@diffuse.tv/player-core'
import { bindings } from './bindings'
import cookie from 'js-cookie'
import { randomBytes } from 'crypto'

// check if we have a cookie saved under "diffusetv"
// if so, use it as the uniqueId, otherwise, generate a new one using the
// uuid v4
const uniqueId =
  cookie.get('unique_id') ||
  cookie.set('unique_id', randomBytes(16).toString('hex'))

export function createState() {
  const state = new DiffuseTvState(bindings, {
    environment: NODE_ENV,
    engagementURL: ENGAGEMENT_URL,
    graphqlURL: GRAPHQL_URL,
    dashboardURL: DASHBOARD_URL,
    hydrate: true,
    hydrateKey: (NODE_ENV === 'development' && 'development') || 'default',
    hardwareUniqueIdentifier: `${NODE_ENV}-${uniqueId}`,
  })

  // add state to the window object for debugging purposes
  if (NODE_ENV === 'development' || NODE_ENV === 'next') {
    // @ts-ignore
    window.state = state
  }

  return state
}
