const $ = process.env

export const NODE_ENV =
  $.REACT_APP_ENVIRONMENT || $.REACT_APP_NODE_ENV || $.NODE_ENV || 'development'
export const ENGAGEMENT_URL = $.REACT_APP_ENGAGEMENT_URL || 'https://d.dffs.eu'
export const GRAPHQL_URL =
  $.REACT_APP_GRAPHQL_URL ||
  (NODE_ENV === 'development' && 'http://localhost:4000/player/graphql') ||
  ''
export const DASHBOARD_URL =
  $.REACT_APP_DASHBOARD_URL || 'https://localhost:3000'

export const SENTRY_DSN =
  $.REACT_APP_SENTRY_DSN ||
  'https://7ed542b59b8e4786afc24f55118b19b0@sentry.mosano.eu/5'
