import React, { FC } from 'react'
import styled from '@emotion/styled'
import type { AudioComponentProps } from './index'

export const Audio: FC<AudioComponentProps> = ({
  hidden,
  broadcastInfo,
  onEnded,
  onError,
  volume,
}) => {
  const Audio = hidden ? HideAudio : DisplayAudio

  return (
    (!!broadcastInfo?.url && (
      <Audio
        onLoadStart={function () {
          //@ts-ignore
          this.volume = (volume || 100) / 100
        }}
        key={broadcastInfo?.unique_id || ''}
        src={broadcastInfo?.url || ''}
        onEnded={(!hidden && (() => onEnded && onEnded())) || undefined}
        onError={
          (!hidden && ((err) => onError && onError(new Error(String(err))))) ||
          undefined
        }
      />
    )) ||
    null
  )
}

const DisplayAudio = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const HideAudio = styled.video`
  display: none;
`
