import React, { PureComponent } from 'react'
import SplitScreen from '../components/split-screen'
import type { DiffuseTvState } from '@diffuse.tv/player-core'

export default class InitializingComponent extends PureComponent<{
  state: DiffuseTvState
}> {
  render() {
    return (
      <SplitScreen
        state={this.props.state}
        title="Diffuse.tv"
        description="Welcome to the Digital Signage revolution."
      />
    )
  }
}
