import { caller } from 'postmsg-rpc'
import {
  IIPFS,
  logger,
  Multihash,
  StorageMedium,
  StorageMediumId,
  URL,
} from '@diffuse.tv/player-core'
import { rpcOptions } from '../service-worker-loader'

const debug = logger(`IPFS Web Client`, 'debug')

export const ipfs: IIPFS = {
  async setup(): Promise<boolean> {
    debug('setup: called')

    // TODO
    return true
  },

  getStorageMediums: caller<StorageMedium[], []>(
    'ipfs_getStorageMediums',
    rpcOptions
  ),
  start: caller<boolean, [StorageMediumId]>('ipfs_start', rpcOptions),
  stop: caller<boolean, [StorageMediumId]>('ipfs_stop', rpcOptions),
  list: caller<Multihash[], [StorageMediumId]>('ipfs_list', rpcOptions),
  has: caller<boolean, [StorageMediumId, Multihash]>('ipfs_has', rpcOptions),
  download: caller<boolean, [StorageMediumId, Multihash, URL]>(
    'ipfs_download',
    rpcOptions
  ),
  invalidate: caller<boolean, [StorageMediumId, Multihash]>(
    'ipfs_invalidate',
    rpcOptions
  ),
  garbageCollector: caller<Multihash[], [StorageMediumId]>(
    'ipfs_garbageCollector',
    rpcOptions
  ),
  getUrl: (storageMediumId: StorageMediumId, multihash: Multihash): URL =>
    `/ipfs/${multihash}`,
}
