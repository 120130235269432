import type {
  DefaultModelMethods,
  IFilterQuery,
  IQueryOptions,
  OnChangeCallback,
  StopListening,
} from '@diffuse.tv/player-core'
import type { Database } from '../database'
import type { ResultSet } from '@lokidb/loki/types/loki/src/result_set'
import type { Doc } from '@lokidb/loki/types/common/types'

interface DefaultT {
  _id: string
}

export interface ModelOptions<T> {
  schemaName: string
  objectToJson?: (object: T | Doc<T & object>) => T
}
export class Model<T extends DefaultT> implements DefaultModelMethods<T> {
  private main: Database
  private options: ModelOptions<T>

  private get collection() {
    const db = this.main?.getDatabase()
    const collection = db?.getCollection<T>(this.options.schemaName)
    return collection
  }

  private get objects() {
    return this.collection.chain()
  }

  constructor(main: Database, options: ModelOptions<T>) {
    this.main = main
    this.options = options
  }

  // methods
  async count(options?: IQueryOptions<T> | undefined): Promise<number> {
    const query = this.parseQueryOptions(this.objects, options)
    return query.count()
  }

  async create(instance: Partial<T>): Promise<T | null> {
    const collection = this.collection

    // console.log("DATABASE create:", JSON.stringify(instance));

    // @ts-ignore
    const obj = collection.insertOne(instance)

    return this.objectToJson(obj)
  }

  async createMany(instances: Partial<T>[]): Promise<T[] | null> {
    const collection = this.collection

    // @ts-ignore
    const objs = collection.insert(instances)

    return objs.map((obj) => this.objectToJson(obj))
  }

  async delete(options: IQueryOptions<T>): Promise<{ affectedRows: number }> {
    const query = this.parseQueryOptions(this.objects, options)
    const affectedRows = query.count()
    query.remove()

    return { affectedRows }
  }

  async update(
    filter: IFilterQuery<T>,
    changes: Partial<T>
  ): Promise<{ affectedRows: number }> {
    const query = this.parseQueryOptions(this.objects, { $where: filter })

    let affectedRows = 0

    query.update((doc) => {
      let changed = false

      for (const [attr, val] of Object.entries(changes)) {
        // @ts-ignore
        if (doc[attr] !== val) {
          // @ts-ignore
          doc[attr] = val
          changed = true
        }
      }

      if (changed) {
        affectedRows++
      }

      return doc
    })

    return { affectedRows }
  }

  find(options?: IQueryOptions<T> | undefined): AsyncIterable<T> {
    const query = this.parseQueryOptions(this.objects, options)
    const objectToJson = this.objectToJson.bind(this)

    return {
      [Symbol.asyncIterator]: async function* () {
        if (query.count() === 0) {
          return
        }

        for await (const doc of query.data()) {
          yield objectToJson(doc)
        }
      },
    }
  }

  subscribe(callback: OnChangeCallback): StopListening {
    const collection = this.collection
    const events = ['insert', 'update', 'delete']

    collection.addListener(events, callback)

    return () => {
      collection.removeListener(events, callback)
    }
  }

  // private methods
  private objectToJson(object: T | Doc<T>): T {
    if (this.options.objectToJson) {
      return this.options.objectToJson(object)
    }

    return object
  }

  private parseQueryOptions(
    objects: ResultSet<T>,
    options: IQueryOptions<T> | undefined
  ): ResultSet<T> {
    if (!options) {
      return objects
    }

    if (options.$where) {
      objects = objects.find(options.$where)
    }

    // apply sorting
    if (options.$sort) {
      // in case it is a string. use it as the key
      if (typeof options.$sort === 'string') {
        objects = objects.simplesort(options.$sort)
      }
      // in case it is an array. use it as the keys
      else if (typeof options.$sort === 'object') {
        const sortArr: Array<keyof T | [keyof T, boolean]> = []

        for (const [attribute, sortDir] of Object.entries(options.$sort)) {
          switch (sortDir) {
            case 'asc':
            case true:
            case 1:
              sortArr.push(attribute as keyof T)
              continue

            case 'desc':
            case false:
            case -1:
              sortArr.push([attribute as keyof T, false])
              continue

            default:
              throw new Error(`Invalid sort direction`)
          }
        }

        objects = objects.compoundsort(sortArr)
      }
    }

    // apply limit and offset
    if (options.$offset) {
      objects.offset(options.$offset)
    }

    if (options.$limit) {
      objects = objects.limit(options.$limit)
    }

    return objects
  }
}
