import React from 'react'
import { DiffuseTvComponent } from './component'
import { createState } from './state'
import mem from 'mem'
import { useAsync } from './utils/use-async'

const memoizedeCreateState = mem(createState)

export const App = () => {
  const { result: state } = useAsync(async () => {
    const state = memoizedeCreateState()

    // launch the setup method
    await state.bindings.database.setup()

    return state
  })

  if (!state) {
    return null
  }

  return (
    <DiffuseTvComponent
      state={state}
      debug={document.location.search.includes('?debug')}
    />
  )
}
