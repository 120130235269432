import React, { SVGProps, FC } from 'react'

const Archive: FC<Partial<SVGProps<SVGSVGElement>> & { fill?: string }> = (
  props
) => (
  <svg
    fill="none"
    viewBox="0 0 48 44"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <g clipPath="url(#clip1)" fill="#fff">
        <path d="M45.525 13.332a2.067 2.067 0 00-1.582-.73H4.057c-.612 0-1.188.266-1.58.73a2.002 2.002 0 00-.46 1.553L4.37 39.46l.007.056c.207 1.55 1.524 2.72 3.063 2.72h33.118c1.618 0 2.968-1.24 3.07-2.776l2.354-24.573a2.004 2.004 0 00-.458-1.554zm-4.958 25.83c-.001-.003-.009-.005-.008-.004l-33.091.005a.098.098 0 01-.039-.047L5.182 15.678h37.635l-2.25 23.483z" />
        <path d="M42.112 6.694a2.057 2.057 0 00-1.555-.706H7.491c-.599 0-1.168.267-1.562.733a2.054 2.054 0 00-.471 1.654l.923 5.998 3.04-.467-.745-4.842h30.696l-.744 4.842 3.04.467.925-6.015a2.085 2.085 0 00-.48-1.664zM30.46 22.293H17.54a1.538 1.538 0 100 3.076h12.92a1.538 1.538 0 100-3.076z" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H48V44H0z" />
      </clipPath>
      <clipPath id="clip1">
        <path fill="#fff" transform="translate(2 2)" d="M0 0H44V44H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default Archive
