// import * as Sentry from "@sentry/react-native";
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { NODE_ENV, SENTRY_DSN } from './config'
import { logger } from '@diffuse.tv/player-core'
import type { CaptureContext } from '@sentry/types'

const error = logger('Sentry', 'error')

if (NODE_ENV !== 'development' && SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: NODE_ENV,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
}

export function reportError(err: any, captureContext: CaptureContext) {
  error('an error was reported', String(err), JSON.stringify(err))

  if (NODE_ENV === 'development' || !SENTRY_DSN) {
    // log to a specific logger instead
    return
  }

  Sentry.captureException(err, captureContext)
}
