import type { IFileSystem, StorageMedium } from '@diffuse.tv/player-core'
import { cachefs, ipfs } from './storage-adapters'
import flatten from 'lodash/flatten'
// import { logger } from "@diffuse.tv/player-core";

// const debug = logger("filesystem binding", "debug");

export const filesystem: IFileSystem = {
  adapters: {
    cachefs,
    ipfs,
  },

  async getStorageMediums(): Promise<StorageMedium[]> {
    const storageMediums = await Promise.all([
      cachefs.getStorageMediums(),
      ipfs.getStorageMediums(),
    ])

    return flatten(storageMediums)
  },
}
