import React, { FC } from 'react'
import type { IBroadcastInfo } from '@diffuse.tv/player-core'
import { Image } from './image'
// import { Vlc } from './vlc'

export interface ImageComponentProps {
  player?: 'default' | 'vlc' | 'rnimage' | string
  hidden?: boolean
  broadcastInfo?: IBroadcastInfo
  onEnded?: () => void
  onError?: (error: Error) => void
}

export const ImageComponent: FC<ImageComponentProps> = (props) => {
  switch (props.player) {
    case 'default':
    case 'htmlimage':
    default:
      return <Image {...props} />
  }
}

export function getPlayers(): string[] {
  return ['htmlimage']
}
