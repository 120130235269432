import React, { FC } from 'react'
import styled from '@emotion/styled'
import type { DiffuseTvState } from '@diffuse.tv/player-core'

import Logo from './logo'
import Display from './icons/display'
import { observer } from 'mobx-react'
// import Orientation from "./icons/orientation";

interface SplitScreenProps {
  title?: string
  description?: string
  state: DiffuseTvState
}

const SplitScreen: FC<SplitScreenProps> = observer(
  ({ title, description, state, children }) => {
    // read these variables form the screen state
    const isResponsive = true
    const isPortrait = false

    return (
      <MainContainer className="main-container">
        <SplitContainer
          stacked={isPortrait}
          responsive={isResponsive}
          className="split-container"
        >
          <LeftContainer className="left-container">
            {(!!title || !!description) && (
              <TextContainer>
                {!!title && <Title>{title}</Title>}
                {!!description && <Description>{description}</Description>}
              </TextContainer>
            )}
            {!!state.player.data?.name && (
              <div>
                <BoxNameContainer>
                  <Display width={50} height={50} />
                  <span>{`Name: ${state.player.data.name}`}</span>
                </BoxNameContainer>
                {/* <BoxNameContainer>
                <Orientation width={50} height={50} />
                <span style={{ textTransform: "capitalize" }}>
                  {`Orientation: ${
                    state.player.data.settings?.screen_orientation?.toLowerCase() ||
                    "Undefined"
                  }`}
                </span>
              </BoxNameContainer> */}
              </div>
            )}
          </LeftContainer>
        </SplitContainer>
        <SplitContainer
          stacked={isPortrait}
          responsive={isResponsive}
          className="split-container"
        >
          <RightContainer className="right-container">
            {!!children && children}
            <LogoContainer className="logo-container">
              <Logo style={{ width: '28.75rem', height: '5.625rem' }} />
            </LogoContainer>
          </RightContainer>
        </SplitContainer>
      </MainContainer>
    )
  }
)

export default SplitScreen

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 100%;
  height: 100vh;
  background: #fff;
`
const SplitContainer = styled.div<{ stacked?: boolean; responsive?: boolean }>`
  padding: 1rem;
  max-width: 100%;
  min-height: 640px;
  width: ${({ stacked, responsive }) =>
    stacked || responsive ? '100%' : '50%'};
  @media screen and (min-width: 1280px) {
    padding: 2.5rem;
    min-width: 640px;
    ${({ responsive }) => responsive && 'width: 50%;'}
  }
`
const LeftContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
`
const RightContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #6d0fb1;
`
const LogoContainer = styled.div`
  align-self: center;
`
const TextContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin-left: 0;
  max-width: 100%;
  @media screen and (min-width: 1280px) {
    max-width: 70%;
    margin-left: 5rem;
  }
`
const BoxNameContainer = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 2.5rem;
  color: #2e384d;
  align-self: flex-start;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    margin-left: 1.25rem;
  }
  @media screen and (min-width: 1280px) {
    margin-left: 5rem;
  }
`
const Title = styled.h1`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3.75rem;
  line-height: 4.874rem;
  color: #2e384d;
`
const Description = styled.h2`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.625rem;
  line-height: 2.5rem;
  color: #2e384d;
`
