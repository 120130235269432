import React, { SVGProps, FC } from 'react'

const Display: FC<Partial<SVGProps<SVGSVGElement>> & { fill?: string }> = (
  props
) => (
  <svg
    fill="none"
    viewBox="0 0 48 48"
    stroke="#2E384D"
    strokeLinejoin="round"
    strokeWidth="3.188"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs />
    <path d="M41.987 9H6.013A3.013 3.013 0 003 12.013v19.474A3.013 3.013 0 006.013 34.5h35.974A3.013 3.013 0 0045 31.487V12.013A3.013 3.013 0 0041.987 9z" />
    <path strokeMiterlimit="10" d="M12 39h24" />
  </svg>
)

export default Display
