import type {
  DiffuseTvState,
  IHardware,
  ISpecs,
  IStats,
} from '@diffuse.tv/player-core'
import { IOSPlatformEnum, IOSTypeEnum } from '@diffuse.tv/player-core'
import convertSize from 'convert-size'
import { filesystem } from './filesystem'

import { getPlayers as getVideoPlayers } from '../screens/video'
import { getPlayers as getImagePlayers } from '../screens/image'
import { getPlayers as getAudioPlayers } from '../screens/audio'

const appStartedAt = Date.now()

export const calculatePixelsToPoints = (pixels: number): number => {
  return pixels * window.devicePixelRatio
}

export const getViewportDensity = () => {
  return window.devicePixelRatio
}

export const getViewportSize = () => {
  const width = window.innerWidth || 0
  const height = window.innerHeight || 0
  return {
    width: width / window.devicePixelRatio,
    height: height / window.devicePixelRatio,
  }
}

export const getViewportOrientation = (): 'landscape' | 'portrait' => {
  const { width, height } = getViewportSize()
  return width > height ? 'landscape' : 'portrait'
}

export const getViewportRatio = (): number => {
  const { width, height } = getViewportSize()
  return width > height ? width / height : height / width
}

export const getViewportMin = (): number => {
  const { width, height } = getViewportSize()
  return Math.min(width, height)
}

export const getViewportMax = (): number => {
  const { width, height } = getViewportSize()
  return Math.max(width, height)
}

export const getSpecs = async (): Promise<ISpecs> => {
  const numberOfCores = navigator.hardwareConcurrency || 0
  const storageMediums = await filesystem.getStorageMediums()

  return {
    cpu: {
      name: 'Unknown',
      serial: '',
      cores: new Array(numberOfCores).fill(0).map(() => ({ speed: 0 })),
    },

    memory: {
      // @ts-ignore
      total: convertSize(`${navigator.deviceMemory || 0} GB`, 'MB'),
    },

    os: {
      name: navigator.platform,
      version: navigator.appVersion,
      platform: IOSPlatformEnum.WebApp,
      type: IOSTypeEnum.Unknown,
    },
    viewport: {
      width: window.innerWidth,
      height: window.innerHeight,
      dpi: window.devicePixelRatio,
    },

    storage: storageMediums.map(({ id, adapter, size }) => ({
      id,
      adapter,
      size,
    })),

    players: {
      video: getVideoPlayers(),
      image: getImagePlayers(),
      audio: getAudioPlayers(),
    },
  }
}

export const getStats = async (state: DiffuseTvState): Promise<IStats> => {
  // @ts-ignore
  const { totalJSHeapSize, usedJSHeapSize, jsHeapSizeLimit } = {
    totalJSHeapSize: 0,
    usedJSHeapSize: 0,
    jsHeapSizeLimit: 0,
    // @ts-ignore
    ...performance.memory,
  }

  const storageMediums = await filesystem.getStorageMediums()
  const activeStorageMediumId = state.filesystem.activeStorageMediumId
  const activeStorageMedium = storageMediums.find(
    (storageMedium) => storageMedium.id === activeStorageMediumId
  )

  return {
    system: {
      cpu: {
        usage: 0,
      },
      memory: {
        allocated: 0,
        free: 0,
        used: 0,
      },
      online: false,
      uptime: Math.round((Date.now() - appStartedAt) / 1000),
    },
    app: {
      cpu: {
        usage: 0,
      },
      memory: {
        allocated: convertSize(`${jsHeapSizeLimit} Bytes`, 'MB'),
        free: convertSize(`${jsHeapSizeLimit - totalJSHeapSize} Bytes`, 'MB'),
        used: convertSize(`${usedJSHeapSize} Bytes`, 'MB'),
      },
      viewport: {
        fps: 0,
      },
      websocket_connected: false,

      // same as the os
      uptime: Math.round((Date.now() - appStartedAt) / 1000),

      active_storage_medium:
        (activeStorageMedium && {
          id: activeStorageMedium.id,
          size: activeStorageMedium.size,
        }) ||
        undefined,
    },
  }
}

export const hardware: IHardware = {
  getViewportSize,
  getViewportOrientation,
  getViewportRatio,
  getViewportMin,
  getViewportMax,

  getStats,
  getSpecs,
}
