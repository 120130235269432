import type {
  IDatabase,
  IDbBroadcastPlan,
  IDbBroadcastReport,
  IDbStatsReport,
} from '@diffuse.tv/player-core'
import { Model } from './utils/database-model'
import Loki from '@lokidb/loki'
import LokiIndexedStorage from '@lokidb/indexed-storage'

export class Database implements IDatabase {
  private db: Loki | undefined
  private dbIsClosed = false

  public getDatabase() {
    const db = this.db

    if (!db) {
      throw new Error('Database not initialized')
    }

    return db
  }

  public BroadcastPlan = new Model<IDbBroadcastPlan>(this, {
    objectToJson,
    schemaName: 'BroadcastPlan',
  })
  public BroadcastReport = new Model<IDbBroadcastReport>(this, {
    objectToJson,
    schemaName: 'BroadcastReport',
  })
  public StatsReport = new Model<IDbStatsReport>(this, {
    objectToJson,
    schemaName: 'StatsReport',
  })

  async setup() {
    // if there's already a database instance that is not yet closed, dont do nothing
    if (this.db && !this.dbIsClosed) {
      return
    }

    const db = new Loki('player-web')
    const adapter = new LokiIndexedStorage('player-web')
    db.on('close', () => (this.dbIsClosed = true))

    // setup collections
    db.addCollection('BroadcastPlan')
    db.addCollection('BroadcastReport')
    db.addCollection('StatsReport')

    await db.initializePersistence({
      adapter,
      autoload: true,
      autosave: true,
    })

    this.dbIsClosed = false
    this.db = db
  }
}

export const database: IDatabase = new Database()

function objectToJson<T extends Record<string, any>>(obj: T): T {
  const newObj = { ...obj }

  if (newObj.meta) {
    delete newObj.meta
  }

  if (newObj.$loki) {
    delete newObj.$loki
  }

  return newObj
}
