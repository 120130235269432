import React, { FC } from 'react'
import type { IBroadcastInfo } from '@diffuse.tv/player-core'
import { Video } from './video'
// import { Vlc } from './vlc'

export interface VideoComponentProps {
  player?: 'default' | 'vlc' | 'rnvideo' | string
  hidden?: boolean
  broadcastInfo?: IBroadcastInfo
  onEnded?: () => void
  onError?: (error: Error) => void
  volume?: number
}

export const VideoComponent: FC<VideoComponentProps> = (props) => {
  switch (props.player) {
    case 'default':
    case 'htmlvideo':
    default:
      return <Video {...props} />
  }
}

export function getPlayers(): string[] {
  return ['htmlvideo']
}
