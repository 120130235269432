import React, { FC } from 'react'
import { observer } from 'mobx-react'
import {
  DiffuseTvState,
  IDbUploadTypeEnum,
  logger,
  ScreenRenderComponent,
} from '@diffuse.tv/player-core'
import PlaybackQRCodeLayout from './layouts/playback-qr-code'
import ScreenLayout from './layouts/screen'
import { DebugComponent } from './components/debug'
import {
  // AudioComponent,
  ImageComponent,
  VideoComponent,
  InitializingComponent,
  LoadingComponent,
  EmptyComponent,
  SetupComponent,
  ErrorComponent,
  BindComponent,
  ArchivedComponent,
} from './screens'

const debug = logger('component', 'debug')

export interface DiffuseTvComponentProps {
  state: DiffuseTvState
  debug?: boolean
}

export const DiffuseTvComponent: FC<DiffuseTvComponentProps> = observer(
  ({ state, debug: debugging }) => {
    // evaluate which state we are right now
    const { playing } = state.scheduler
    const renderComponent = state.screen.renderComponent
    const screenProps = { ...state.screen.props, state }
    const Wrapper = state.screen.hasQRCode ? PlaybackQRCodeLayout : ScreenLayout

    debug(`rending with mode ${renderComponent}`)

    return (
      <Wrapper {...{ state }}>
        {(debugging || state.player.data?.settings?.debug) && (
          <DebugComponent state={state} />
        )}

        {renderComponent === ScreenRenderComponent.INITIALIZING && (
          <InitializingComponent state={state} />
        )}
        {renderComponent === ScreenRenderComponent.LOADING && (
          <LoadingComponent state={state} />
        )}
        {renderComponent === ScreenRenderComponent.DEVICE_BIND && (
          <BindComponent state={state} />
        )}
        {renderComponent === ScreenRenderComponent.ERROR && (
          <ErrorComponent state={state} />
        )}
        {renderComponent === ScreenRenderComponent.SETUP && (
          <SetupComponent state={state} />
        )}
        {renderComponent === ScreenRenderComponent.EMPTY && (
          <EmptyComponent state={state} />
        )}
        {renderComponent === ScreenRenderComponent.ARCHIVED && (
          <ArchivedComponent state={state} />
        )}

        <VideoComponent
          key="playing-video"
          player={state.screen.players.video}
          volume={state.player?.data?.settings?.volume || undefined}
          hidden={renderComponent !== ScreenRenderComponent.PLAYING_VIDEO}
          broadcastInfo={
            (playing?.upload?.type === IDbUploadTypeEnum.video && playing) ||
            undefined
          }
          {...screenProps}
        />

        <ImageComponent
          key="playing-image"
          player={state.screen.players.image}
          hidden={renderComponent !== ScreenRenderComponent.PLAYING_IMAGE}
          broadcastInfo={
            (playing?.upload?.type === IDbUploadTypeEnum.image && playing) ||
            undefined
          }
          {...screenProps}
        />
      </Wrapper>
    )
  }
)
