import { useCallback, useEffect, useState } from 'react'

interface IUseAsyncState<T> {
  result: T | undefined
  error: Error | undefined
  loading: boolean
}

export function useAsync<T extends any>(asyncFunction: () => Promise<T>) {
  const asyncCallback = useCallback(asyncFunction, [])
  const [state, setState] = useState<IUseAsyncState<T>>({
    loading: true,
    result: undefined,
    error: undefined,
  })

  useEffect(() => {
    asyncCallback().then(
      (res) => setState({ loading: false, result: res, error: undefined }),
      (err) => setState({ loading: false, result: undefined, error: err })
    )
  }, [asyncCallback])

  return state
}
