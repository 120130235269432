import React, { FC, useCallback, useRef } from 'react'
import styled from '@emotion/styled'
import type { VideoComponentProps } from './index'
import { useEffect } from 'react'

export const Video: FC<VideoComponentProps> = ({
  hidden,
  broadcastInfo,
  onEnded,
  onError,
  volume,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const VideoComponent = hidden ? HideVideo : DisplayVideo

  const adjustVolume = useCallback(() => {
    if (!videoRef?.current) return

    //@ts-ignore
    videoRef.current.volume =
      ((volume && ((volume > 100 && 100) || (volume > 0 && volume))) || 0) / 100
  }, [videoRef, volume])

  useEffect(() => {
    adjustVolume()
  }, [adjustVolume])

  useEffect(() => {
    if (!videoRef?.current) return
    videoRef.current?.play()
  }, [broadcastInfo?.unique_id])

  return (
    (!hidden && !!broadcastInfo?.url && (
      <VideoComponent
        ref={videoRef}
        onLoad={adjustVolume}
        onLoadStart={adjustVolume}
        playsInline
        key={broadcastInfo?.unique_id || ''}
        src={broadcastInfo?.url || ''}
        onEnded={(!hidden && (() => onEnded && onEnded())) || undefined}
        onError={
          (!hidden && ((err) => onError && onError(new Error(String(err))))) ||
          undefined
        }
      />
    )) ||
    null
  )
}

const DisplayVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const HideVideo = styled.video`
  display: none;
`
