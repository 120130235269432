import { logger } from '@diffuse.tv/player-core'
import { CallerOptions } from 'postmsg-rpc'

// load the service worker
import registerServiceWorker from 'service-worker-loader!./service-worker'
const debug = logger(`Service Worker Loader`, 'debug')

const serviceWorkerReady = (async function () {
  try {
    const registrations = await navigator.serviceWorker.getRegistrations()

    await Promise.all(
      registrations.map((registration) => registration.unregister())
    )
  } catch {}

  return registerServiceWorker({
    scope: '/',
    type: 'classic',
    updateViaCache: 'all',
  })
})()

export const rpcOptions: CallerOptions = {
  addListener(...args) {
    navigator.serviceWorker.addEventListener(...args)
  },
  removeListener(...args) {
    navigator.serviceWorker.removeEventListener(...args)
  },

  async postMessage(data: any) {
    await serviceWorkerReady

    debug(`Posting a message:`, data)
    self.navigator.serviceWorker.controller?.postMessage(data)
  },
}
